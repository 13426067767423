
// Signup

.signup-terms {
  padding-bottom: 16px;
  span {
    font-size: 10px!important;
  }
}

.meal-plan-type-card {
  cursor: pointer;
  transition: $transition;

  .ant-card-meta {
    align-items: center;
    display: flex;
  }

  &.active {
    background: $brand-color;

    .ant-card-meta-title, .ant-card-meta-description {
      color: white;
    }
  }

  .ant-card-meta-title {
    margin: 0!important;
  }

  .ant-card-meta-avatar {
    padding: 0 !important;
    margin-bottom: 0;

    .ant-avatar {
      width: 60px;
      height: 60px;
    }
  }
}

.signup-complete-modal {
  .ant-form-item-label {
    @include mq(ms) {
      padding: 0;
    }
  }

  .ant-form-item {
    @include mq(ms) {
      margin-bottom: 6px;
    }
  }

  .ant-card-body {
    @include mq(ms) {
      padding: 1em!important;
    }
  }
}


// Meal settings
.preference-wrapper {
  @include mq(ms) {
    text-align: center;
  }

  .ant-radio {
    display: none !important;
  }

  .ant-radio-wrapper {
    width: 100% !important;
  }

  .ant-radio-wrapper > span {
    width: 100%;
  }

  .ant-avatar {
    @include mq(sm) {
      margin-right: 10px;
    }
  }

  .ant-radio-wrapper-checked {
    .meal-plan-type-card {
      background: $brand-color;

      .ant-card-meta-title, .ant-card-meta-description {
        color: white;
      }

    }
  }
}

.signup-imperial-height {
  .ant-form-item-control-input-content {
    flex: none !important;
  }
}