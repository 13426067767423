// Override css here
.darkmode {
  $boxshadow: 0;

  .ant-tag.ant-tag-default {
    background: rgba(45, 45, 45, 0.6) !important;
  }

  .react-modal-sheet-container {
    background: #141414!important;
  }

  .reaction-emote {
    background: #141414!important
  }
}

.ant-card {
  background: transparent;
}

.darkmode {
  .recipe-collapse {
    .ant-collapse-header {
      background: #141414 !important;
    }
  }

  .recharts-default-tooltip {
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .stebby-btn {
    background: rgba(255, 255, 255, 0.4) !important;
  }

  .ant-menu {
    background: transparent !important;
  }

  .custom-chart-tooltip {
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .date-arrow {
    color: white !important;
  }

  // Scrollbar
  .scroll-wrapper, #scroll-wrapper, .ingredient-substitutes {
    /* Track */
    &::-webkit-scrollbar-track {
      background: #1f1f1f;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(136, 136, 136, 0.2);
    }
  }

  .ambilight {
    display: none !important;
  }

  .header-affix > .ant-affix {
    box-shadow: none;
  }
}

