#diary {

  .food-row {
    padding: 12px 0;
    border-bottom: solid 1px $border-color;
    flex-flow: row !important;

    .food-name {
      cursor: pointer;
    }

    .food-row-actions {
      gap: 8px;
      display: flex;
      font-size: 16px;

      path {
        fill: $brand-color;
      }
    }

    &.active {
      .food-name {
        color: $brand-color;
      }
    }

    p {
      margin: 0;
    }

    &.eaten {
      p {
        text-decoration: line-through;
      }
    }

    .ant-input-number {
      width: 70px;
      margin: auto;
      display: block;
    }

    .amount {
      font-weight: bold;
    }
  }

  .page-row {
    margin-bottom: 20px;
  }

  .progress-data {
    text-align: center;
    margin-bottom: 1em;

    .ant-progress, .ant-progress-inner {
      width: 70px !important;
      height: auto !important;

      &.error {
        .ant-progress-circle-path, .ant-progress-text {
          color: red !important;
          stroke: red !important;
        }
      }
    }

    .ant-progress-text {
      font-size: 14px;
      @include mq(sm) {
        font-size: 16px;
      }
    }

    p {
      margin-bottom: 0;
      @include mq(sm) {
        font-size: 14px;
        margin-top: 6px;
        font-weight: 600;
      }
    }

    .ant-progress-circle.ant-progress-status-success .ant-progress-text {
      color: #52c41a;
    }

    // Tooltip button
    button {
      display: block;
      margin: auto;
      @include mq(sm) {
        display: inline;
      }
    }
  }

  .date-selection {
    i {
      cursor: pointer;
    }
  }

  .daily-progress {
    .energy-progress {
      .ant-progress-bg, .ant-progress-inner {
        height: 6px !important;
      }
    }
  }

  .recipe-collapse {
    border: none;
    font-size: 14px !important;
    border-radius: 0!important;
    @include mq(sm) {
      font-size: 16px !important;
    }

    .ant-collapse-header {
      background: white;
    border-radius: 0!important;
      font-weight: bold;
      border-bottom: dashed 2px $border-color;
      font-size: 14px;
      padding-left: 24px !important;

      i {
        left: 6px !important;
      }

      @include mq(sm) {
        font-size: 16px;
      }

    }

    .ant-collapse-item {
      background: transparent;
      border: none;
    }

    .ant-collapse-content {
      border: none !important;
    }
  }

  .select-unit {
    .ant-select-selection {
      margin-left: 6px;
    }
  }
}

.time {
  opacity: 0.7;
  font-size: 12px;
}

.empty-food {
  margin: 2em 0;
  opacity: 0.5;
  width: 100%;

  img {
    filter: grayscale(1);
  }
}

.ingredient-descriptions {
  @include mq(ms) {
    .ant-descriptions-item-label, .ant-descriptions-item-content {
      padding: 6px 16px!important;
      font-size: 12px!important;
    }
  }
}

.diary-recipe {
  .ant-collapse-header {
    display: flex!important;
    align-items: center!important;
  }
}