// Variables
$brand-color: #0EB8AE;
$brand-yellow: #efc207;
$brand-purple: #7E8FEF;
$light-green: #43e1d7;
$light-yellow: #FCF3CD;
$black: #262626;
$light-grey: #FAFAFA;
$grey: #828282;
$shadow: 0 2px 4px 0 hsla(0, 0%, 61.2%, .2);
$primary-font: 'Nunito', sans-serif;
$transition: all 0.4s ease-in-out;
$border-color-light: #eee;
$border-color-dark: rgba(253, 253, 253, 0.12);
$boxshadow-light: 0 4px 15px #eaeff1;
$boxshadow-dark: 0 0 0 1px rgba(253, 253, 253, 0.12);
$blue-light: #e6fffa;
$blue-dark: #112c27;
$white: rgba(255, 255, 255, 0.85);
$text-color: rgba(0, 0, 0, 0.88);

// test
$border-color: var(--border-color);
$boxshadow: var(--boxshadow);
$background: var(--background);
$color: var(--color);

#light-mode, .lightmode {
  --border-color: #{$border-color-light};
  --boxshadow: #{$boxshadow-light};
  --background: #{$blue-light};
  --color: #{$text-color};
}

#dark-mode, .darkmode {
  --border-color: #{$border-color-dark};
  --boxshadow: #{$boxshadow-dark};
  --background: #{$blue-dark};
  --color: #{$white};
}

.fitlap-logo-font {
  font-family: "Kaushan Script", cursive;
  font-weight: 400;
  font-style: normal;
}

// Path to Assets
$img-path: '../assets/images';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

// Media Queries
@mixin mq($breakpoint-slug: "sm") {
  @if $breakpoint-slug == "xs" {
    // Extra small devices (phones, 1-768px)
    @media (min-width: 1px) {
      @content;
    }
  } @else if $breakpoint-slug == "ms" {
    // Small devices (tablets, 768px and up)
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint-slug == "sm" {
    // Small devices (tablets, 768px and up)
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint-slug == "md" {
    // Medium devices (desktops, 992px and up)
    @media (min-width: 992px) {
      @content;
    }
  } @else if $breakpoint-slug == "lg" {
    // Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      @content;
    }
  }
}
