.mobile-nav-menu-bottom {
  position: fixed;
  z-index: 500;
  bottom: 0;
  width: 100%;
  text-align: center;

  svg {
    font-size: 18px;
  }

  .ant-radio-group {
    width: 100% !important;
    background: white;
    display: flex;
    box-shadow: 0 0px 4px 0 hsla(0, 0%, 61.2%, .2);

    .ant-radio-button-wrapper {
      //border-top: 0.5px solid #CBCBCB !important;
      border: none;
      border-radius: 0;
      flex: 1 1 0px;
      padding-top: 10px;
      padding-bottom: 30px;

      &:before {
        background: transparent !important;
      }

      .ant-radio-button-checked {
        border-top: 2px solid $brand-color !important;
      }
    }
  }
}

.mobile-navigation-drawer {

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-drawer-body {
    padding: 6px !important;
  }

}

.ant-drawer-close {
  position: absolute;
  right: 0;
}

.ant-drawer-title {
  text-align: center;
}
