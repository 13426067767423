.video-card-iframe {
  .ant-card-cover {
    cursor: pointer;

    &:hover {
      .play-circle-video {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

.video-card-list {
  cursor: pointer;
  &:hover {
    .play-circle-video {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
  &.unavailable {
    opacity: 0.65;
  }
}

.play-circle-video {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 60px;
  color: white;
  cursor: pointer;
  transition: $transition;
  &.small {
    font-size: 24px;
  }
}

.skeleton-with-image {
  margin-bottom: 12px;
  span.ant-skeleton-avatar {
    width: 100px!important;
    height: 70px!important;
    border-radius: 8px!important;
  }
}