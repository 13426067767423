.fitlap-challenge {

  .ant-badge {
    width: 100% !important;
    height: 100% !important;
  }
}

.challenge-card {
  padding: 10px;
  height: 100%;
 // border: solid 1px $border-color;
  box-shadow: $boxshadow;
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  line-height: 14px !important;
    min-height: 100px;
  @include mq(sm) {
  font-size: 14px;
    height: 160px;
  }

  .item-icon {
    margin-bottom: 10px;
    max-width: 30px;
    display: block;
  }

  &:hover {
    opacity: 0.7;
  }

  &.completed {
      opacity: 0.5; /* Glow Effect */
  }
}

.challenge-modal {
  .ant-modal-footer {
    text-align: center;
  }
}

.list-item-row {
  cursor: pointer;
  user-select: none;

  .ant-list-item-action {
    transition: $transition;
  }

  &:hover {

    .ant-list-item-action {
      margin-right: -6px !important;
    }

    strong {
      color: $brand-color !important;
    }
  }
}

.challenge-progress {
  .ant-progress-text {
    color: $brand-color!important;
  }
}

.team-name {
  &:first-letter {
    text-transform: uppercase;
  }
}

.avatar-group-min {
  .ant-avatar {
    background: #eee;
    font-size: 12px;
    color: grey;
  }
}