.christmas-page {
  .ant-layout-content {
    padding: 0 !important;

    .bg-container {
      background: linear-gradient(315deg, #05092d, #290e80);
      background-size: cover;
        background-position: 10% 100%;
      @include mq(sm) {
        background-position: 100% 70%;
      }

      position: absolute;
      width: 100%;
      height: 500px;
    }

    .wavy-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;
      margin-bottom: -1px;

      @include mq(sm) {
        svg {
          position: relative;
          display: block;
          width: calc(100% + 1.3px);
          height: 150px;
          transform: rotateY(180deg);
        }
      }

      .shape-fill {
        fill: #FFFFFF;
      }
    }
  }
}

.christmas {
  .ant-modal-body {
    min-height: auto!important;
    padding: 20px!important;
  }
}

.advent-calender-header {
  @include mq(sm) {
    text-align: left
  }
  text-align: center;
  min-height: 220px;
  color: white !important;

  h1 {
    margin-bottom: 0 !important;
    padding: 0 !important;
    color: white !important;

    @include mq(sm) {
      font-size: 60px !important;
    }
  }

  h4, h2 {
    color: white !important;
  }


}

.page-container {
  padding: 20px;
}

.advent-calender-page {
  background: white!important;
  padding-bottom: 60px;
  position: relative;

  .advent-card {
    background: white;
    border: solid 1px $border-color;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;

    .ant-card-body {
      padding: 12px;
      @include mq(sm) {
        padding: 24px;
      }
    }

    &:hover {
      .animated-gift {
        opacity: 0.7;
      }
    }

    h1 {
      color: #262626;
      margin: 0;
      @include mq(ms) {
        font-size: 14px !important;
      }
    }

    .advent-card-number {
      @include mq(sm) {
        font-size: 22px;
      }
      position: absolute;
      font-weight: 800;
      bottom: 0;
      opacity: 0.3;
      width: 100%;
      left: 0;
    }
  }
}

.animated-gift {

  &.disabled {
    svg {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  svg {
    width: 40px !important;
    @include mq(sm) {
      width: 60px !important;
    }
  }
}

.snowflake {
  z-index: 25;
  box-shadow: 0 0 10px white;
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}

@for $i from 1 through 50 {
  .snowflake:nth-child(#{$i}) {
    --size: #{random(5) * 0.15}vw;
    animation: snowfall #{15 + random(10)}s linear infinite;
    @include mq(sm) {
      --size: #{random(5) * 0.05}vw;
      animation: snowfall #{5 + random(15)}s linear infinite;
    }

    --left-ini: #{random(20) - 10}vw;
    --left-end: #{random(20) - 10}vw;
    left: #{random(100)}vw;
    animation-delay: -#{random(10)}s;
  }
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px);
}

.animated-giftbox {
  cursor: pointer;
  display: inline-block;

  div {
    width: 26px!important;
    @include mq(sm) {
      width: 20px!important;
    }
    display: flex;
    align-items: center;
  }
}

.advent-calender-menu {
  padding-left: 24px;
  border: solid 1px #eee;
}

.scratch-card {
  &:not(.open) {
  cursor: grab;
    border: solid 2px white;
    box-shadow: $boxshadow;
    border-radius: 24px;
  }
}
