.goal-journey {
  ul > li.ant-list-item {
    border-bottom: dashed 2px $border-color;
    padding: 8px 0;
  }

  .ant-list {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .edit {
    text-align: right;
  }
}

.goal-graph {
  .select-graph-date {
    margin-left: auto;
    display: block;
  }

  .goal-graph-container {
    font-size: 12px;
    padding: 20px 0;
    margin-bottom: 40px;
  }

  .recharts-default-legend {
    text-align: right !important;
  }
}

.goal-overview {
  text-align: center;

  .goal-overview-stats {
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      display: block;
    }

    .block {
      margin-bottom: 20px;
    }
  }
}

.progress-info {
  color: $black;
  font-size: 20px;

  .progress-weight {
    margin: 0;
    display: block;
    font-size: 20px;
    font-weight: bold;
  }

  span {
    font-size: 12px;
  }
}

// Sizes tab
.sizes-graph {
  .ant-select-selection {
    margin-top: 10px;
  }
}

.user-sizes {
  margin-top: 10px;
  margin-bottom: 20px;
  .ant-input-number {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 30px;
  }

  .ant-btn {
    width: 100%;
    margin-top: 18px;
    font-weight: 500;
  }

  .ant-form-item-label {
    padding: 0!important;
  }

  label {
    font-size: 12px!important;
    @include mq(sm) {
      font-size: 14px!important;
    }
  }
}

.figure-container {
  text-align: center;
}

.user-figure {
  font-size: 300px;
  svg {
    max-width: 150px;
  }

  path {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.chest {
    #female_svg__chest,
    #male_svg__chest {
      fill: $brand-color;
    }
  }

  &.belly {
    #female_svg__belly,
    #male_svg__belly {
      fill: $brand-color;
    }
  }

  &.arm {
    #female_svg__arm,
    #male_svg__arm {
      fill: $brand-color;
    }
  }

  &.hip {
    #female_svg__hip,
    #male_svg__hip {
      fill: $brand-color;
    }
  }

  &.thigh {
    #female_svg__thigh,
    #male_svg__thigh {
      fill: $brand-color;
    }
  }
}

// Health
.feedback-form-item {
  padding: 6px 0;

  .ant-radio-group {
    margin-top: 6px;
    .ant-radio-wrapper {
      margin-right: 12px;
    }

    label {
      span {
        display: block;
        text-align: center;
        padding: 0 2px;
        font-size: 12px;
      }
    }
  }
}

.survey-table {
  margin-bottom: 2em;

  .ant-table-body {
    margin: 0!important;
  }

  span, td {
    font-size: 12px!important;
  }

  th {
    width: 70px!important;
  }

  .survey-table-column {
    border: solid 1px $border-color;
  }
}

.remove-question {
  color: red;
}

.link-account-row {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: dashed 2px $border-color;
  button {
    border-radius: 24px;
  }
}

// Activity
.activity-page {
  .title-wrapper {
    margin-bottom: 10px;
  }

  .ant-table-title {
    font-size: 14px;
  }

  table {

    th {
      font-weight: bold;

      &:last-child {
        text-align: right;
      }
    }

    .active-row {
      background: #dff7f2 !important;
    }
  }
}

.table-operations {
  margin: 20px 0 10px 0;

  button {
    font-size: 14px;
    margin: 0 10px 10px 0;
  }
}


.join-challenge {
  display: block;
  margin: 20px auto;
}

.activity {
  text-align: center;
  @include mq(sm) {
    text-align: left;
  }

  .steps-circle {
    margin-bottom: 20px;
    @include mq(sm) {
      margin-bottom: 0;

    }

    .steps-progress {
      .ant-typography {
        display: block;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .daily-steps {
    .ant-typography {
      display: block;
    }
  }
}

.activity-leaderboard {
  margin-top: 10px;
  margin-bottom: 20px;
}

// Health page

.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 18px;
  height: 18px;
  color: #8a8a8a!important;
  font-size: 18px!important;
  line-height: 1;

  &.anticon-smile {
    color: $brand-color!important;
  }
}

.icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
}

.icon-wrapper .anticon:last-child {
  right: 0;
}

.weight-comment-textarea {
  max-width: 320px!important;
  margin: auto!important;
}

.goals-sidebar {
  padding: 20px;
  border-radius: 12px;
  background: transparent;
  border: solid 1px $border-color;
  margin: 40px 0;
}


// Leaderboard

.podium-wrapper {
  display: flex;
  align-items: flex-end;
  text-align: center;
  margin-bottom: 20px;
}

.podium {
  border-radius: 10px;
  position: relative;
  .medal {
    font-size: 24px;
    margin-top: -6px;
    @include mq(sm) {
      margin-top: -8px;
    }
  }
}

.podium .name {
  font-weight: bold;
  margin-top: 10px;
}

.podium .score {
  color: #555;
}

.podium-rank {
  font-size: 24px;
  font-weight: bold;
  background: #5925dc;
  color: white;
  padding: 5px 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;

  &.rank-1 {
    height: 100px;
    background: #5925dc;
    color: white;
    @include mq(sm) {
      height: 140px;
    }
  }

  &.rank-2 {
    height: 80px;
    background: #7a5af8;
    @include mq(sm) {
      height: 120px;
    }
  }

  &.rank-3 {
    height: 60px;
    background: #9b8afb;
    @include mq(sm) {
      height: 100px;
    }
  }
}