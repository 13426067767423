.notification-badge {
  sup {
    top: 8px!important;
    right: 8px!important;
  }
}

.notifications-title {
  margin: 0 !important;
}

.close-icon {
  cursor: pointer;
  padding: 6px;
}

.notifications-popover {
  max-width: 460px!important;
  width: 100%;
  @include mq(sm) {
    width: auto;
  }

  .ant-popover-title {
    border-bottom: dashed 1px $border-color;
    padding: 10px;
    padding-top: 4px!important;
  }
}

.notifications-container {

  &.full-height {
    max-height: inherit!important;
  }

  overflow: hidden;
  text-align: left;
  overflow-y: auto;
  max-height: 300px;

  @include mq(sm) {
    max-height: 420px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(130, 130, 130, 0.5);
      }
    }
  }

  .notification-row {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 12px;

    &:not(:last-child) {
      border-bottom: 1px dashed $border-color;
      border-radius: 0;
    }

    &:hover {
      //box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
      -webkit-filter: brightness(70%);
    }
  }

  .notification-content {
    p {
      margin: 0 !important;
    }
  }

  .created-at {
    font-size: 12px;
    color: $brand-color;
  }
}