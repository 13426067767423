#recipes {
  .card-title {
    @include mq(sm) {
      font-size: 16px !important;
    }
  }
}

.recipe-skeleton-container {
  margin-bottom: 100px;
}

.recipe-skeleton {
  li {
    border-radius: 12px !important;

    &:first-child {
      height: 90px !important;
      @include mq(sm) {
        height: 191px !important;
      }
    }

    &:last-child {
      display: none !important;
    }
  }

  &.top-recipe {
    li {
      &:first-child {
        height: 100px !important;
        @include mq(sm) {
          height: 123px !important;
        }
      }

      &:last-child {
        display: none !important;
      }
    }
  }
}

.ant-divider-inner-text > h4.ant-typography {
  @include mq(ms) {
    font-size: 16px !important;
    font-weight: bold !important;
  }
}

.recipe-checkable-tag {
  border-radius: 16px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 4px 12px;
  margin-bottom: 10px !important;

  @include mq(sm) {
    font-size: 14px;
    padding: 4px 12px;
  }

  &.loading {
    color: $border-color !important;
  }

  &:not(.ant-tag-checkable-checked) {
    border: solid 1px $border-color;

    &:hover {
      @include mq(ms) {
        background: inherit !important;
        color: inherit !important;
      }
    }
  }

}

// Ingredients
.recipe-ingredient {
  width: 100%;

  &.swapped {
    color: $brand-color;
  }

  img {
    width: 20px;
  }

  .swap-ingredient {
    text-align: right;
    color: $brand-color;
  }
}

.ingredient-substitutes {
  height: 260px;
  overflow: auto;
  @include mq(sm) {
    height: 400px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
    border-radius: 6px;
  }

  .ant-list-item {
    border-bottom: dashed 2px $border-color;
    cursor: pointer;
    text-transform: lowercase;

    &:hover {
      color: $brand-color;
    }
  }
}

.search-ingredient {
  width: 260px !important;
  @include mq(sm) {
    width: 100% !important;
  }
}

// Single recipe modal
.single-recipe-modal {
  @include mq(sm) {
    width: 900px !important;
  }

  .ant-modal-content {
    padding: 0 !important;
    padding-bottom: 20px !important;
    @include mq(ms) {
      min-height: 100vh;
      border-radius: 0 !important;
    }
  }

  @include mq(ms) {
    top: 0;
    margin-top: 0;
    height: 100%;
    padding: 0;
    max-width: 100%;
  }

  .ant-modal-close {
    i {
      background: white;
      padding: 8px;
      border-radius: 50px;
    }
  }

  .ant-modal-header {
    display: none;
  }

  @include mq(md) {
    top: 50px;
    height: auto;
    width: auto;
  }

  .ant-modal-body {
    overflow: hidden;
    padding: 0;
  }

  .ant-modal-content {
    padding-bottom: 40px;
    overflow: hidden;
    @include mq(md) {
      min-height: inherit;
      border-radius: 6px;
    }
  }

  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }

  .title {
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .recipe-info {
    margin-bottom: 10px;
  }

  img {
    width: 100%;
  }

  .recipe-modal-img-wrapper {
    position: relative;
    //margin-bottom: 10px;
    height: 200px;
    @include mq(sm) {
      height: 250px;
      margin-top: 40px;
    }

    .recipe-modal-img {
      border-radius: 12px;
      z-index: 1 !important;
      position: relative;
    }
  }

  .add-meal-to-menu {
    @include mq(sm) {
      margin: 0;
      width: 100%;
    }
  }

  .fb-like {
    padding-top: 20px;
  }
}

.skeleton-img-box {
  li {
    width: 100% !important;
    height: 150px !important;
  }
}

.ingredient-icon {
  max-width: 20px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  cursor: pointer;

  &.partner {
    width: 50px !important;
    max-width: 50px !important;
    margin: 0 !important;
  }
}

.recipe-condiments {
  padding: 10px 0;

  span {
    padding-left: 5px;
    text-transform: lowercase;

    &:not(:last-child) {
      &::after {
        content: ','
      }
    }
  }
}

.add-to-favorites {
  color: white;
  z-index: 1;
  position: absolute;
  right: 0;
  padding: 8px 12px;
  cursor: pointer;
  width: auto !important;
  display: inline-block !important;

  svg {
    font-size: 21px !important;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4));
  }
}

.user-recipe-badge {
  position: absolute;
  z-index: 25;
  left: 5px;
  margin-top: 10px;
  border-radius: 24px;
  padding: 0;
  line-height: 15px;
  cursor: pointer;
  border: none;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  background: white;

  .recipe-author-name {
    padding: 0 6px;
  }

  &:hover {
    opacity: 1 !important;
  }
}

.post-actions {
  margin: 12px 0;
}

.recipe-filters-modal {
  margin-top: 12px;
}

.recipe-nutridata {
  cursor: pointer;
  height: 22px !important;
  min-width: 22px !important;
  width: 22px !important;

  &.small {
    display: block;
    margin: auto;
    @include mq(sm) {
      display: inline-flex;
    }
  }
}

.recipe-tags {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px;
  margin-top: -30px;
  z-index: 5;
  position: absolute;


  .ant-tag-default {
    font-size: 10px !important;
    background: white;
    @include mq(sm) {
      font-size: 12px !important;
    }
  }
}

.recipe-nutridata-row {
  span:not(:first-child) {
    &:before {
      content: " | ";
    }
  }

  span {
    font-size: 10px !important;
    @include mq(sm) {
      font-size: 12px !important;
    }
  }
}

.recipe-card {
  .author-tag {
    position: absolute;
    left: 6px;
    top: 6px;
    @include mq(sm) {
      left: 10px;
      top: 10px;
    }

    background: white !important;
    max-width: 100px;
    width: auto !important;
    z-index: 1;
    border-radius: 24px !important;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.ant-select-selection-search-input {
  font-family: $primary-font !important;
}

.buy-ingredient {
  margin: 6px !important;
}

.desktop-recipe-wrapper {
  @include mq(sm) {
    margin-bottom: 20px;
  }
}

.ai-recipe-ingredients {
  margin: 0;

  li {
    padding-bottom: 6px;
    padding-top: 10px;
    list-style: none;
  }

  li:not(:last-child) {
    border-bottom: dashed 2px $border-color;
  }
}