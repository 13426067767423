.payment-section {
  margin-bottom: 40px;
}

.products {
  @include mq(sm) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.recurring-canceled {
  margin-top: 40px;
  color: $brand-purple;
}

.cancel-recurring {
  color: #ff4d4f !important;
}

.product-card {
  text-align: center;
  border-radius: 12px !important;
  height: 95%;
  margin-bottom: 1.5rem;
  border: solid 1px $border-color;
  box-shadow: $shadow !important;

  @include mq(sm) {
    min-height: 240px;
  }

  .title {
    font-size: 16px;
    margin: 0;
    font-weight: 800;
    @include mq(sm) {
      font-size: 1.5rem;
      margin: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex: 1 1 auto;
    padding: 0.5rem;
  }

  .ant-badge {
    margin: 6px 0 !important;
    @include mq(sm) {
      margin-bottom: 1rem;
    }
  }

  .product-features {
    padding-left: 20px;
    text-align: left;
    display: block;

    li {
      font-size: 14px;
      padding: 5px 0;
      color: $grey;

      i {
        margin-right: 6px;
      }
    }
  }

  .price-total {
    margin-top: auto;
    color: #6c757d !important;
    margin-bottom: 0.5rem;

    span {
      font-weight: 600;
    }
  }

  button {
    display: block;
    margin: auto;
    font-size: 12px !important;
    width: 50%;
    @include mq(sm) {
      width: 75%;
      font-size: 14px !important;
      margin-top: 0px;
      margin-bottom: 10px;
    }

    &:hover {
      color: white !important;
    }
  }

  .pricing-card-title {
    margin: 0;
    font-weight: 600;
    @include mq(sm) {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .text-primary {
    color: $brand-color;
  }

  .text-muted {
    color: #6c757d !important;
  }

  &.active {
    color: white !important;
    background: linear-gradient(-45deg, #ee7752, #e73c7e) !important;

    .title {
      color: white !important;
    }

    button {
      background: white !important;
      color: $black !important;
      border-color: transparent !important;
    }

    .pricing-card-title {
      color: white !important;
    }

    li, .per-month, .price-total, .price-before {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    .badge {
      border: solid 1px;
      background: transparent !important;
    }
  }

  // Mobile product card
  &.mobile {
    .card-body {
      padding: 8px !important;
    }

    .price-before {
      display: inline-block;
      margin-left: 6px;
    }

    p {
      margin: 0 !important;
    }

    button {
      font-size: 12px;
      max-height: 30px;
      line-height: 14px;
    }

    .badge {
      margin: 6px 0 !important;
    }
  }
}

.price-before {
  display: block;
  text-decoration: line-through;
}

.per-month, .price-total, .price-before {
  font-size: 12px;
  font-weight: normal;
  @include mq(sm) {
    font-size: 14px;
  }
}

// Subscription page
.ios-payment-section {
  margin: 40px 0;
}

#payment-methods-section {
  img {
    width: 20px;
  }
}

.ant-statistic-content {
  font-size: 14px !important;
  font-weight: 600 !important;
  @include mq(sm) {
    font-size: 20px !important;
  }
}

.offer-countdown {
  margin: 1em 0;
}

// Subscription 2
.product-card-mobile {
  border: solid 1px #c6c6c6;
  border-radius: 12px;
  position: relative;
  padding: 10px;
  margin-bottom: 1em;
  cursor: pointer;

  p {
    margin: 0 !important;
  }

  .ant-row {
    min-height: 40px;
  }

  .ant-col-16 {
    border-left: solid 1px #c6c6c6;
  }

  .ant-col-8 {
    text-align: left;
  }

  &.active {
    border: solid 2px #e73c7e;
  }

  .new-badge {
    font-size: 12px;
    right: 20px;
    border-radius: 12px;
    position: absolute;
    top: -10px;
    z-index: 5;
    background: linear-gradient(-45deg, #ee7752, #e73c7e) !important;
    color: white;
    padding: 0px 10px;
  }

  .text-muted {
    color: #6c757d !important;
  }

  .fw-600 {
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}