.search-recipe-modal {
  @include mq(ms) {
    max-width: 100%!important;
  }
  padding: 0;
  margin: 0;
  top: 0;
  overflow: hidden;
  width: 100%!important;
  height: 100%;

  @include mq(lg) {
    border-radius: 12px;
    height: auto;
    top: 30px;
    margin: auto;
    max-width: 1000px;
  }

  .ant-modal-body {
      padding-top: 20px;
  }

  .ant-modal-header {
    display: none;
    margin-top: 20px;
  }

  .recipe-skeleton {
    li {
      &:first-child {
        height: 90px !important;
        @include mq(sm) {
          height: 120px!important;
        }
      }
    }
  }

  .ant-modal-header {
    text-align: center;
    border-bottom: none;
    @include mq(sm) {
      border-bottom: 2px dashed $border-color;
    }
  }

  .ant-modal-content {
    height: auto;
    min-height: 100vh;
    border-radius: 0;
    @include mq(sm) {
      min-height: 100%;
    }
  }

  .recipe-search-results {
    @include mq(sm) {
      padding: 24px;
    }

    .recipe-image {
      @include mq(sm) {
        border-radius: 6px;
        box-shadow: $shadow;
        background: $border-color;
        overflow: hidden;
      }
    }
  }
}

.search-wrapper {
  overflow: hidden;
}

#scroll-wrapper {
  max-height: calc(100vh - 240px);

  @include mq(lg) {
    max-height: calc(100vh - 400px);
  }

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
    border-radius: 6px;
  }
}

.sort-modal {
  margin-bottom: 1em;
}