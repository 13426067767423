// Feed post skeleton
.post-skeleton {
  li {
    border-radius: 12px!important;
  }
  
  .ant-skeleton-title {
    width: 100% !important;
    height: 180px!important;
  }
}

.feed-post {
  margin-bottom: 20px;
  display: flex;
  align-items: stretch;

  .ant-card {
    width: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: $boxshadow;
    overflow: hidden;
    @include mq(sm) {
      border: 1px solid $border-color;
      box-shadow: none;
      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
      }
    }
  }

  .ant-card-meta-title {
    white-space: normal;
    font-size: 14px;
    margin-bottom: 0 !important;
    height: 63px;
    overflow: hidden;
    @include mq(sm) {
      font-weight: 700;
      font-size: 16px;
      height: 48px;
    }
  }

  .ant-card-body {
    padding: 12px;
    @include mq(sm) {
      padding: 16px;
    }
  }

  .ant-card-cover {
    overflow: hidden;
    height: 100px;
    @include mq(sm) {
      height: 160px;
    }
  }

  .description {
    display: none;
    @include mq(sm) {
      height: 120px;
      overflow: hidden;
      display: block;
    }
  }

  .author {
    font-size: 12px;
    @include mq(sm) {
      font-size: 14px;
    }
  }

  .team {
    svg {
      margin: 10px auto 0 auto;
      display: block;
      @include mq(sm) {
        margin: 0;
      }
    }

    p {
      display: none;
      @include mq(sm) {
        display: block;
        text-align: right;
      }
    }
  }
}

.post-meta {
  .description {
    color: $grey;
    line-height: 20px;
    font-size: 14px;
  }

  .ant-avatar {
    cursor: pointer;
    margin-right: 5px;

    @include mq(sm) {
      margin-right: 10px;
    }
  }

  .date {
    font-size: 12px;
    color: $grey;
    margin: 0;
  }

  .author {
    font-weight: 500;
  }

  .team {
    text-align: right;
    svg {
      width: 42px;
      height: 20px;
    }

    p {
      font-size: 12px;
      color: #828282;
      margin: 0;
    }
  }
}

.comments-count-badge {
  display: inline-block;
  font-size: 12px;
  color: $brand-color;
}

// Quill
.quill {
  background: transparent;
  .ql-snow {
    border-color: $border-color!important;
  }

  .ql-editor {
    min-height: 300px;
  }
}