.shopping-list-page, .shopping-list-public-page {
  background: white;
  @include mq(sm) {
    background: #fafbfc;
  }

  .nav-section {
    border: none;
    @include mq(sm) {
      border-bottom: solid 1px $border-color;
    }
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      border-color: $brand-color;
    }
  }
}

.darkmode {
  .ant-collapse-item {
    background: #141414!important;
  }
}

.shopping-list-recipes {
  margin-top: 12px;
  @include mq(sm) {
    background: transparent;
  }

  &.skeleton {
    .ant-collapse-header {
      padding-top: 23px !important;
      padding-bottom: 23px !important;
    }
  }

  .ant-collapse-item {
    box-shadow: $boxshadow;
    margin-bottom: 20px;
    border-radius: 6px!important;
    background: white;
    border: none;
  }

  .ant-collapse-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    @include mq(sm) {
      font-weight: 500;
    }

    .ant-skeleton-paragraph {
      margin: 0;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: dashed 1px $border-color;
    }
  }

  .active-day {
    .ant-collapse-header {
      color: $brand-color;
      border-left: solid 4px;
      transition: none;
    }
  }
}

.shopping-list-recipes-wrapper {
  @include mq(sm) {
    padding: 0 20px;
  }

  .shopping-list-recipe {
    width: 100%;
    padding: 12px 0;
    border-top: dashed 2px $border-color;
  }

  div:first-child .shopping-list-recipe {
    border: none !important;
  }
}

.shopping-list-items {
  overflow: inherit;

  .ant-tabs-bar {
    margin-bottom: 0;
    display: none;
    @include mq(sm) {
      display: block;
    }
  }

  .ant-tabs-content {
    @include mq(sm) {
      padding: 20px 0 40px 0;
      background: white;
      border: 1px solid $border-color;
      box-shadow: $boxshadow;
      border-top: none;
    }
  }
}

.shopping-list-ingredient {
  width: 100%;
  padding: 8px 0;
  border-bottom: dashed 2px $border-color;

  &:last-child {
    border-bottom: none;
  }
}


.shopping-list-category {
  margin-top: 20px;
}

.shopping-list-add-item {
  margin-top: 10px;

  .ant-btn {
    border-radius: 0 50px 50px 0;
  }
}

.delete-shopping-list-item {
  float: right;
  margin-top: 5px;
  cursor: pointer;
}

.mobile-tabs {
  @include mq(sm) {
    display: none !important;
  }
}

.mobile-tabs, .training-levels, .goal-nav {
  width: 100%;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

  label {
    border-color: $brand-color !important;
    color: $brand-color;
    white-space: nowrap;
    text-align: center;
    flex: 1 1 0px;
    &:before {
      background-color: $brand-color !important;
    }
  }

  .ant-radio-button-wrapper {

    &:not(.ant-radio-button-wrapper-checked) {
      box-shadow: none !important;
      outline: none !important;
      color: #818993;
      border-color: $border-color !important;
    }

    &:before {
      display: none;
    }
  }

  .ant-radio-button-wrapper-checked {
    color: white;
  }
}

.shopping-list-tip {
  text-align: center;
  background: $light-yellow;
  margin-top: 80px;

  &:before {
    width: 100%;
    height: 80px;
    position: absolute;
    transform: skew(0deg, -5deg);
    background: #fcf3cd;
    margin-top: -30px;
    @include mq(sm) {
      width: 0;
      height: 0;
      transform: inherit;
      margin: 0;
      border-left: 350px solid #fff;
      border-bottom: 50px solid transparent;
      border-bottom: 120px solid transparent;
      position: absolute;
    }
  }

  @include mq(sm) {
    margin: 20px 0 0 0 !important;
    box-shadow: $boxshadow;
  }

  .tip-title {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .tip {
    padding-bottom: 20px;
    @include mq(sm) {
      padding-top: 40px;
    }

    .anticon {
    }
  }

  .shopping-list-tip-header {
    font-weight: bold;
    margin-top: 10px;
    @include mq(sm) {
      margin-top: 20px;
      text-align: left;
    }
  }

  .close-tip {
    position: absolute;
    right: 0;
    padding: 6px 12px;
    cursor: pointer;
    top: 0;
    z-index: 10;
  }
}

.shopping-list-actions {
  float: right;
  z-index: 10;
  padding: 0 !important;

  .ant-btn {
    overflow: hidden;
    padding: 0;
    width: 100%;

    span {
      margin-left: 5px;
    }
  }
}

.send-shopping-list-modal, .recipe-notes-modal {
  .ant-modal-body {
    label {
      color: #828282;
      font-weight: 300;
      line-height: 18px;
      display: block;
    }

    text-align: left;
  }
}

// public shpoping list
.shopping-list-public-items {
  overflow: inherit;

  .ant-tabs-bar {
    display: none;
    margin-bottom: 0;
    @include mq(sm) {
      display: block;
    }
  }

  .ant-tabs-content {
    @include mq(sm) {
      padding: 20px 0 40px 0;
      background: white;
      border: 1px solid $border-color;
      box-shadow: $boxshadow;
      border-top: none;
    }
  }
}

.public-shopping-list-title {
  text-align: center;
  @include mq(sm) {
    text-align: left;
  }
}

.public-shopping-list-nav {
  margin-bottom: 0 !important;
}

.shopping-list-family {
  margin-bottom: 10px;

  .ant-tag-checkable-checked {
    color: white !important;
    border: none;
  }

  .ant-tag {
    cursor: pointer;
    color: $grey;
    border: solid 1px $border-color;

    svg {
      margin-right: 3px;
    }
  }
}

$selver-red: #F01319;
.selver-btn {
  background-color: $selver-red!important;
  color: white!important;
  &:hover {
    opacity: 0.7;
  }
}

.swap-ingredient {
  text-transform: capitalize;
  text-align: right;
  color: $brand-color;
  white-space: nowrap;

  span {
    cursor: pointer;
  }
}

.info-box-circle {
  text-align: center;
  padding: 50px 0;

  .circle-bg {
    width: 155px;
    padding: 30px;
    border-radius: 60% 100% 60% 100% / 100% 70% 100% 70%;
    background: $background;
    margin: auto;
    justify-content: space-around;
    display: inline-flex;
    align-items: center;
    transform: rotate(-20deg);
    height: 100px;
  }

  p {
    position: absolute;
    min-width: 250px;
    transform: rotate(20deg);
    margin: 0;
    @include mq(sm) {
      min-width: 300px;
    }
  }
}

.selver-modal {
  .title {
    margin-bottom: 20px !important;
  }

  .selver-logo {
    max-width: 200px;
    margin: auto;
    display: block;
    margin-bottom: 30px !important;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    border-bottom: dashed 2px $border-color;
    padding: 10px 0;

    &.ant-checkbox-wrapper-checked {
      //border-color: $brand-color;
    }
  }

  .ant-checkbox-wrapper > span:nth-child(2) {
    width: 100%;
  }

  .ant-checkbox-wrapper-disabled {
    span {
      //color: $color!important;
    }
  }
}