.profile-level {
  h2 {
    margin: 0;
  }

  .level-progress {
    .ant-progress-inner {
      height: 12px;
    }

    .ant-progress-bg {
      height: 12px !important;
      background: linear-gradient(to right, #efc207, #ffa40e);
    }
  }

  .title-section {
    text-align: center;
    @include mq(sm) {
      text-align: left;
    }
  }

  .user-avatar {
    border: solid 2px #ec9922;
    padding: 2px;
    @include mq(sm) {
      border: none;
      padding: 0;
    }
    img {
      border-radius: 50%;
    }
  }
}

.muted-text {
  color: #989898
}

.small-text {
  font-size: 14px;
  font-weight: normal;
}

.recent-activity {
  border-radius: 12px;
  padding: 10px 20px;
  transition: $transition;
  margin-bottom: 20px;
  border: solid 1px $border-color;
  @include mq(sm) {
    border: none;
  }

  .ant-list-item-meta-title {
    font-size: 14px !important;
    font-weight: normal !important;
  }
}

.achievements {
  img {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .achievement-count {
    cursor: pointer;
    font-size: 12px;
  }
}

.popover-title {
  font-weight: bold;
}

.badge-container {
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
}

// Level nav
.nav-profile {
  @include mq(sm) {
    padding: 0 8px;
    display: none;
  }

  @include mq(md) {
    display: inline-block;
  }

  sup {
    font-size: 10px;
    background: #ff4757;
    right: 0px;
    height: 16px;
    width: 28px;
    padding: 0;
    line-height: 16px;
  }
}

// Level up modal
.level-up-modal {
  text-align: center;

  &.christmas {
    .ant-modal-body {
      min-height: 300px;
    }
  }

  .ant-modal-body {
    min-height: 500px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
  }

  .fade-in-content {
    z-index: 10;
    display: block;
    text-align: center;
    opacity: 0;
    -webkit-animation: 1s ease 0s normal forwards 1 fadein;
    animation: 1s ease 0s normal forwards 1 fadein;

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes fadein {
      0% {
        opacity: 0;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.section {
  margin-bottom: 20px!important;
  @include mq(sm) {
    margin-bottom: 40px!important;
  }
}

.daily-nutrition {
  h4 {
    font-size: 16px;
    @include mq(sm) {
      font-size: 24px;
    }
  }
}

.section-card {
  background: transparent;
  border: solid 1px $border-color;
  margin-bottom: 20px;
  border-radius: 12px;
  transition: $transition;
  padding: 20px;
  @include mq(sm) {
    padding: 20px 10px;
    text-align: center;
    height: 100%;
  }

  .section-info {
    z-index: 5;
    cursor: pointer;
    position: absolute;
    color: #828282;
    padding: 8px;
    right: 0px;
    top: 0px;
    @include mq(sm) {
      right: 12px;
      top: 12px;
    }
  }

  &:hover {
    border-color: transparent;
    box-shadow: $boxshadow !important;
  }

  .section-card-description {
    display: flex;
    flex-direction: column;
    height: 100%;
    p {
      margin: 0;
    }
    button, a {
      margin-top: auto;
    }
  }

  button {
    font-size: 14px;
    border-radius: 24px;
  }

  .water-progress {
    i, svg {
      color: #19aef0 !important;
    }
  }

  .weight-progress {
    i, svg {
      color: #e73c7e !important;
    }
  }


  .task-progress {
    i, svg {
      color: #7E8FEF !important;
    }
  }

  .ant-progress-status-exception {
    i, svg {
      color: #f3f3f3 !important;
    }
  }
}

.water-progress {
    .ant-progress-bg {
      background: #19aef0 !important;
    }
    i, svg {
    color: #19aef0 !important;
  }
}

.eaten-progress {
  &.success {
    .ant-progress-bg {
      background: #52c41a!important;
    }

    .ant-progress-circle-path {
      color: #52c41a!important;
      stroke: #52c41a!important;
    }
  }

  .ant-progress-circle-path {
    color: #efc207!important;
    stroke: #efc207!important;
  }

  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #52c41a;
  }

  .ant-progress-bg {
    background: #efc207;
  }

  &.error {
    .ant-progress-bg {
      background-color: red !important;
    }

    .ant-progress-circle-path, .ant-progress-text {
      color: red !important;
      stroke: red !important;
    }
  }
}

.achievement-icon {
  border: solid 1px $border-color!important;
}

.hidden-group {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.edit-profile {
  color: $brand-color;
  font-size: 16px;
  cursor: pointer;
}


.user-level-badge {
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-align: center;

  &.animated {
    animation: glow 2s infinite;
    animation-delay: 1s;

    @keyframes glow {
      0% {
        filter: contrast(1);
      }

      50% {
        filter: contrast(1.3);
      }

      100% {
        filter: contrast(1);
      }
    }
  }

  &.icon {
    img {
      max-width: 22px;
    }

    h1 {
      font-size: 10px;
      position: absolute;
      margin: 0;
      top: 40%;
      padding: 0;
      font-family: arial;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
    }

  }

  img {
    max-width: 140px;
  }

  h1 {
    font-weight: 600;
    font-size: 50px;
    position: absolute;
    margin: 0;
    top: 34%;
    padding: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ec9922;
    //text-shadow: 0px 0px 15px #ffffff;
  }

  &.small {
    h1 {
      font-size: 18px;
    }

    img {
      max-width: 50px;
    }
  }

  &.medium {
    h1 {
      font-size: 34px;
    }

    img {
      max-width: 100px;
    }
  }
}

.fitlap-drawer {
  .ant-drawer-content {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    @include mq(sm) {
      border-radius: 0;
    }
  }

  .ant-drawer-header {
    text-align: center;
  }

  .ant-drawer-header, .ant-drawer-body {
    font-size: 14px!important;
  }
}

.mobile-login-streak {
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  border: solid 1px $border-color;
  border-radius: 12px;
  @include mq(sm) {
    display: none;
  }
}

.default-table {
  width: 100%!important;
  margin-bottom: 40px;

  .ant-table-column-title {
    font-weight: bold;
  }

  thead th {
    background: white;
  }

  td, th {
    padding: 12px 6px !important;
    @include mq(sm) {
      padding: 16px !important;
    }
  }
}

.beginner-level {
  a {
    color: $color!important;
  }
  background: $background;
  border: transparent !important;
}

// Level badge animations
.mid-level {
  border: transparent !important;
  color: white;
  background: linear-gradient(-45deg, #3e9f2c, #43b92d, #3e9f2c);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}

.high-level {
  border: transparent !important;
  color: white;
  background: linear-gradient(-45deg, $brand-color, #05b4fa, $brand-color);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}

.very-high-level {
  border: transparent !important;
  color: white;
  background: linear-gradient(-45deg, #e73c7e, #ee8952, #e73c7e);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}

.master-level {
  border: transparent !important;
  color: white;
  background: linear-gradient(-45deg, #e73c7e, #8b52ee, #533ce7);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ant-tabs-nav-list {
  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 20px!important;
  }
}