.chatroom-content.ant-layout-content {
  padding: 0 !important;
  @include mq(sm) {
    padding: 20px !important;
  }
}

.chatroom-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.chat-wrapper {
  @include mq(sm) {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    //box-shadow: $boxshadow;
  }
}

.chat-drawer {
  .ant-drawer-body {
    padding: 20px 0 !important;
  }
}

.message-container {
  position: relative;
  flex: 1;
  border-radius: 12px;
  padding: 6px 12px;

  .message-reaction {
    display: none;
    z-index: 25;
    right: 0;
    @include mq(sm) {
      margin-right: -26px;
    }
    top: 0;
    position: absolute;
  }

  &:hover, &:active {
    .message-reaction {
      display: block;
    }
  }
}

.reaction-counter {
  position: absolute;
  display: flex;
  bottom: -12px;
}

.reaction-counter > div {
  border: none;
  box-shadow: $shadow;
  padding-right: 4px;
  padding-left: 4px;
  background: rgba(255, 255, 255, 1);
  border-radius: 12px;
}

.reaction-counter > div > div {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
}

.search-chat-users {
  .ant-modal-footer {
    text-align: center;
  }
}

.post-feed-reaction > div {
  box-shadow: none !important;
  background: transparent !important;
}

.post-feed-card {
  margin: 16px 0 !important;
  border: none !important;

  .blog-comments {
    margin-top: 0 !important;
  }

  .ant-card-body {
    padding: 0;
    @include mq(sm) {
      padding: 24px !important;
    }
  }

  @include mq(sm) {
    border-radius: 12px;
    box-shadow: $boxshadow;
  }

  @include mq(ms) {
    border-radius: 0 !important;
    border-bottom: dashed 1px $border-color !important;

  }

  .ant-skeleton-with-avatar {
    .ant-skeleton-paragraph {
      margin-top: 0 !important;
    }
  }

  .ant-skeleton {
    li {
      height: 10px !important;
    }
  }

  .post-feed-actions {
    @include mq(ms) {
      padding-bottom: 12px;
    }
  }
}

.post-feed-add-post {
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  box-shadow: $boxshadow;
  @include mq(sm) {
    padding: 16px;
  }
}

.private-chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 100dvh;

  @include mq(sm) {
    max-height: 70vh;
  }

  .private-chat-scrollable {
    max-height: calc(100dvh - 100px - 60px);
    min-height: 0;
    flex: 1;
    overflow-y: auto;
  }
}

.float-upload {
  .ant-upload-list {
    position: absolute;
    bottom: 0px;
      right: 50px;

    .ant-upload-list-item {
      background: white !important;
    }
  }
}

.float-button-group {
  z-index: 123;
  right: 12px;
  bottom: 110px;
  @include mq(sm) {
    right: 40px;
    position: absolute !important;
    bottom: 80px;
  }
}

.default-upload {
  text-align: right;
  .ant-upload {
    background: transparent!important;
    border: none!important;
    width: auto!important;
    height: auto!important;
  }
}