// Meal plan card
.recipe-card-meal-type {
  font-size: 14px;
  margin-bottom: 6px;
  display: block;
  font-weight: 600;
}

.recipe-card {
  border: none;

  .ant-card-cover {
    cursor: pointer;
    border-radius: 12px;

    img {
      border-radius: 12px !important;
      box-shadow: $boxshadow;
      transition: $transition;
      // Resize failed to crop images
      aspect-ratio: 18 / 11;
    }

    &:hover {
      img {
        transition: $transition;
        filter: brightness(0.85);
      }
    }
  }

  .card-title {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    @include mq(sm) {
      font-size: 18px;
    }
  }

  .ant-card-actions {
    svg {
      font-size: 18px !important;
    }
  }

  .ant-card-body {
    padding: 1em 0 !important;
  }

  .ant-card-actions {
    border: solid 1px $border-color;
    border-radius: 12px;

    li {
      margin: 0;
      padding: 5px 0 3px 0;
      @include mq(sm) {
        padding: 8px 0;
      }

      .anticon {
        line-height: 16px !important;
      }
    }
  }
}

// Single meal

#single-meal {
  @include mq(sm) {
    margin-top: 20px;
  }
  .ant-image {
    @include mq(ms) {
     // margin-left: -20px;
      //margin-right: -20px;
    }
  }

  .ant-image {
    z-index: 1;
  }

  .single-meal-image {
    margin: auto;
    display: block;
    border-radius: 12px;
    width: 100%;

    @include mq(sm) {
      overflow: hidden;
      box-shadow: $boxshadow;
      border-radius: 12px;
    }
  }

  .single-meal-info {
    text-align: center;
    @include mq(sm) {
      text-align: left;
    }
  }
}

.instruction-list {
  ul, ol {
    padding-left: 20px;

    li {
      padding-bottom: 10px;
    }
  }
}

// Menu date mobile

.menu-date-mobile {
  margin: 1em 0;

  ul {
    @include mq(sm) {
      &::-webkit-scrollbar {
        height: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba(136, 136, 136, 0.5);
        border-radius: 6px;
      }
    }

    padding: 0 10px 10px 10px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    list-style: none;
    margin: 0;
    width: 100%;
  }

  .menu-date {
    cursor: pointer;
    margin-top: 2px !important;
    box-shadow: $boxshadow;
    border-radius: 12px;
    text-align: center;
    display: inline-block;
    padding: 6px 0;
    margin-right: 20px;
    width: 15%;

    span {
      display: block;
    }

    &.active {
      background: $brand-color;
      color: white;
      font-weight: bold;
      span {
        color: white!important;
      }
    }
  }
}

.daily-progress {
  @include mq(ms) {
    border-radius: 12px;
    margin-bottom: 12px;
  }

  .nutri-text {
    font-weight: 600;
    text-align: center;
    display: block;
    @include mq(sm) {
      margin-top: 6px;
    }
  }

}
.daily-progress-info {
  &.energy {
    text-align: left;
  }
  font-size: 12px;
  @include mq(sm) {
    font-size: 14px;
    span {
      font-size: 12px;

    }
  }
}

.meal-family-members {
  .meal-family-member {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: solid 1px $border-color;

    .ant-input-number {
      width: 60px!important;
    }
  }
}

.slider-dots {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;

  .dot {
    transition: $transition;

    &.active {
      transition: none;
      background: $brand-color;
      width: auto;
      font-size: 12px;
      height: auto;
      border-radius: 12px;
      padding: 0 6px;
      color: white;
    }

    height: 6px;
    width: 6px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}

.meal-skeleton {
  @include mq(sm) {
    margin-bottom: 40px;
  }

  .ant-skeleton-header {
    @include mq(ms) {
      display: none;
    }

    @include mq(sm) {
      .ant-skeleton-avatar {
        width: 300px;
        border-radius: 12px;
        height: 200px;
      }
    }
  }

  li {
    &:first-child {
      height: 200px !important;
      @include mq(sm) {
        height: 18px !important;

      }
    }
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes swipe {
  0% { transform: rotate(60deg); }
  100% { transform: rotate(0deg); }
}

.daily-action-buttons  {
    margin-left: 12px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}

// Training modal
.training-modal {
  .menu-date-mobile {
    margin-top: 0!important;
  }

  .family-member-training-list {
    .ant-list-item-meta {
      display: flex;
      align-items: center;
      .ant-list-item-meta-title {
        font-weight: normal!important;
        margin: 0!important;
      }
    }
  }
}

.participant-physical-activity {
  width: 100%;
  border-bottom: dashed 1px $border-color;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

// Ambilight behind images
.ambilight {
  // No light in desktop
  &.hide-desktop {
    @include mq(sm) {
      display: none;
    }
  }

  opacity: 0.75; // change for best results
  filter: blur(10px); // change for bigger/smaller shine
  // fill the box with light:
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0; // keep the .light under the .image
}