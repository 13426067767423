.home-cooks-page {
  //background: white;
  padding-bottom: 80px!important;
  @include mq(sm) {
    padding-top: 40px!important;
  }
}

// Home cooks
.home-cook-card {
  padding: 1em;
  box-shadow: $boxshadow;
  border-radius: 12px;
  cursor: pointer;
  background: transparent;

  .ant-tag {
    border-radius: 12px;
    background: transparent!important;
  }

}

.default-avatar {
  //padding: 6px;
}

.home-cook-tags {
  margin-top: 10px;
  margin-bottom: 10px;

  .ant-tag {
    border-radius: 12px;
    margin-bottom: 6px;
  }

  .default-tag {
    background: transparent!important;
  }
}

.author-badge-level {
  sup.ant-scroll-number {
    box-shadow: $shadow;
    background: white !important;
    color: rgba(0, 0, 0, 0.88)!important;
    font-size: 10px;
    transform: none;
    bottom: -4px;
    right: -4px;
    top: auto;
    z-index: 1;
    max-height: 14px;
    line-height: 14px;
  }
}

.search-home-cook-header {
  margin-bottom: 1em;
  @include mq(sm) {
    margin: 4em;
  }

  .chef {
    display: none;
    @include mq(sm) {
      display: block;
      width: 100px;
      position: absolute;
      margin-top: -120px;
      margin-right: -60px;
      z-index: 5;
      &.female {
        margin-left: -200px;
        width: 80px;
      }
    }
  }

  .search-home-chef {
    width: 100%;
    @include mq(sm) {
      width: 540px;
    }
  }
}

.home-cook-header {
  text-align: center;

  .ant-avatar {
    margin: auto;
    @include mq(sm) {
      margin: inherit;
    }
  }

  h2 {
    margin-bottom: 10px!important;
  }

  @include mq(sm) {
    text-align: left;
    h2 {
      margin-bottom: 0!important;
    }
  }
}