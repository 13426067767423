.default-alert {
  align-items: start!important;
  .anticon {
    margin-top: 5px;
  }
}

.avatar-wrapper {
  position: relative;
}

.avatar-fitlap-plus {
  position: absolute;
  bottom: -20px;
  right: -3px;
  font-size: 26px;
  color: deepskyblue;
  text-shadow: 0 0 black;
  @include mq(sm) {
    bottom: -16px;
    right: -2px;
  }
}