.search-ingredients-modal {
  top: 20px;
  @include mq(sm) {
    top: 100px;
  }

  .ant-modal-title {
    font-weight: bold;
    text-align: center;
  }

  .ant-modal-content {
    border-radius: 12px!important;
    overflow: hidden;
  }

  button {
    font-size: 14px!important;
  }
}

.ingredient-substitutes {
  height: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
    border-radius: 6px;
  }

  .ant-list-item {
    border-bottom: dashed 2px $border-color;
    cursor: pointer;

    &:hover {
        color: $brand-color;
    }
  }
}

.new-ingredient-form {
  .ant-form-item-label {
    line-height: 20px;
    padding: 0;
  }
  label, .ant-form-explain {
    font-size: 14px;
  }
}

.ingredient-found-modal {
  max-width: 300px;
  .ant-modal-title {
    font-weight: bold;
    text-align: center;
  }

  .ant-modal-body {
    font-size: 14px;
  }

  button {
    font-size: 14px!important;
  }

  li {
    padding-bottom: 10px;
  }

  .danger-link {
    margin-top: 10px;
    color: red!important;
  }

  textarea {
    font-size: 14px;
  }
}

.app-required-modal {
  max-width: 90%;
  text-align: center;

  .custom-icon {
    color: $black;
    display: block;
    font-size: 40px;
  }

  .ant-modal-confirm-btns {
    float: none!important;
    button {
      font-size: 14px;
    }

    .ant-btn-primary {
      @include mq(sm) {
        display: none;
      }
    }
  }

  .desktop-app-links {
    display: none;
    @include mq(sm) {
      display: block;
    }
  }
}

@keyframes move {
  0% {
    margin-top: -10px;
  }
  50% {
    margin-top: 10px;
  }
  100% {
    margin-top: -10px;
  }
}

.lock-wrapper {
  width: 100%!important;
}

.ingredient-filters {
  gap: 8px;
  padding: 12px 0;
    justify-content: center;

  button {
    border-radius: 24px;
    font-size: 12px!important;
  }
}

.ingredient-list-item {
  display: block;
}

.ingredient-list-item-data {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.input-action-icon {
  background: white;
  transition: $transition;
  padding: 10px 14px;
  margin-bottom: 20px;
  margin-left: 8px;
  border-radius: 24px;
  box-shadow: $boxshadow;
  @include mq(sm) {
    box-shadow: $shadow;
  }

  &:hover {
    color: $brand-color;
  }
}

.create-recipe-image {

  &.minimal {
    margin-bottom: 0!important;
    .ant-upload {
      width: 32px !important;
      height: 32px !important;
    }

    &.uploaded {
      .ant-upload {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  &.uploaded {
    .ant-upload {
      width: 320px!important;
      height: 200px!important;
    }
  }

  .ant-upload {
    width: 260px!important;
    height: 40px!important;
    margin: auto!important;
    display: block;
    overflow: hidden!important;

    img {
      //height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
}

#infinite-ingredients {
  padding-right: 6px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 62vh;
  @include mq(sm) {
    height: 50vh;
  }
  padding-bottom: 0;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
    border-radius: 6px;
  }

  .ant-list-item {
    border-bottom: dashed 1px $border-color;
    cursor: pointer;

    .ingredient-name {
      text-transform: lowercase;
    }

    &:hover {
      color: $brand-color;
    }
  }

  .custom-icon {
    padding: 0 6px;
  }

  .price-range {
    padding: 0 6px;
  }
}

.recipe-stats {
  .recipe-progress-circle {
    text-align: center;

    .ant-progress-text {
      font-size: 14px;
      font-weight: 500;
      @include mq(sm) {
        font-size: 18px;
      }

      span {
        color: $grey;
        font-weight: normal;
        font-size: 10px;
        @include mq(sm) {
          font-size: 12px;
        }
      }
    }

    &.error {
      .ant-progress-text {
        color: red !important;
      }
    }

    .ant-progress-inner {
      height: 100px!important;
      width: 100px!important;

      @include mq(sm) {

      height: 120px!important;
      width: 120px!important;
      }
    }

    .ant-progress-status-success {
      .ant-progress-text {
        //color: $brand-color;
      }
    }
  }


  .recipe-nutrients-progress {
    text-align: center;

    .correct {
      color: $brand-color !important;
      font-weight: bold;
    }

    .zero {
      color: #989898;
    }

    .invalid {
      color: red;
      font-weight: bold;
    }

    span {
      cursor: pointer;
      font-size: 12px;
      @include mq(sm) {
        font-size: 14px;
      }
    }

    .muted-text {
      margin-bottom: 1em;
      display: block;
      font-size: 12px;
    }

    .ant-slider {
      margin-top: 0;
      @include mq(sm) {
        margin-top: 12px;
      }
    }
  }
}

.create-recipe-warning {
  margin-bottom: 20px;
}

.ingredient-name {
  font-weight: 600;
}

.group-tag {
  border-radius: 24px;
  margin-right: 0;
  font-size: 10px!important;
  line-height: 14px;
  max-height: 20px!important;
  @include mq(sm) {
    line-height: 20px;
    font-size: 12px!important;
  }

  &.new {
    color: white;
    background: red;
    border: none;
  }
}

.ingredients-table {
  .group-title {
    margin-top: 10px;
    font-weight: bold;
    border-bottom: dashed 1px $border-color;
    padding: 8px 0;

    span {
      float: right;
      font-weight: normal;
    }
  }

  .rows {
    .ingredients-table-row:last-child {
      border: none!important;
    }
  }

  .ingredients-table-footer {
    margin: 10px 0;

    span {
      float: right;
    }
  }

  .condiments {
    padding: 10px 0;
    border-top: dashed 1px $border-color;
    font-size: 14px;
  }

  .ingredients-table-header {
    font-weight: bold;
  }

  .ingredients-table-row {
    padding: 12px 0;
    border-bottom: dashed 1px $border-color;

    .grey {
      color: #b7b7b7;
    }

    .name {
      overflow: hidden;
      cursor: pointer;

      &.active, &:hover {
        color: $brand-color;
      }
    }

    .ant-input-group-compact {
      margin: 0 6px;
      max-width: 95px;
      display: flex;

      .ant-select-selection__rendered {
        margin-right: 7px!important;
      }

      .ant-select-arrow {
        display: none!important;
      }
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ingredient-info {
  color: $grey;
  font-size: 12px;
}

.remove-ingredient {
  color: red;
  position: absolute;
  margin-top: 4px;
  display: block;
  left: -10px;
  @include mq(sm) {
    left: -20px;
  }
}

.remove-ingredient-mobile {
  color: red;
  font-size: 14px;
  margin-left: 2px;
  @include mq(sm) {
    display: none;
  }
}

.remove-condiment {
  color: red;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
}

.ingredient-unit-dropdown {
  width: 60px!important;
}

.ingredient-unit-dropdown-diary {
  min-width: 100px!important;
}

.generate-recipe-content {
  .collapse-ingredients {
    float: right;
    cursor: pointer;

    i {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.up {
      i {
        transform: rotate(180deg);
      }
    }
  }

  .recipe-multiplier {
    padding: 0;
    box-shadow: none;
    margin: 20px 0!important;
  }
}

.ingredient-amount-field {
  display: flex;
  justify-content: center;
  align-items: center;

  // Unit selector width
  .ant-select-selector {
    width: 40px!important;
    text-align: left;
    .ant-select-selection-item {
      padding: 0!important;
    }
  }

  .ant-input-affix-wrapper {
    max-width: 70px;
    margin: 0 6px;

    input {
      padding-right: 20px!important;
    }

    @include mq(sm) {
      max-width: 100px;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.generate-recipe {
  margin-bottom: 24px;
}

.loading-more {
  text-align: center;
  font-size: 24px;
  padding: 20px 0;
}