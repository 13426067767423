.report-table-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: dashed 2px $border-color;

  &.title-row,
  &.footer-row {
    color: $black;
    @include mq(sm) {
      font-size: 16px;
    }
  }

  &.footer-row {
    border: none;
  }
}

.report-table {
  .eaten {
    color: $brand-color;
  }

  .not-eaten {
    color: $grey;
  }
}

.report-summary {

  .progress-title {
    line-height: 10px;
    margin-bottom: 0;
    color: $grey;
    font-size: 14px;
  }

  .report-progress-bar {
    margin-bottom: 15px;

    .ant-progress-bg {
      background: $brand-color;
    }

    .anticon-check-circle {
      color: $brand-color;
    }
  }
}

.wellness {
  font-size: 60px;
  margin: 20px auto;
  display: block;
}