.login-site-layout {

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }


  @include mq(sm) {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('https://fitlap.ee/wp-content/images/recipes/55218-kookosemaitseline-laatse-suvikorvitsapada-original.jpg');
    background-size: cover;
  }

  footer {
    background: transparent !important;
    text-align: center;
    @include mq(sm) {
      color: white !important;
    }
  }

  .ant-layout-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.login-form-wrapper {
  @include mq(sm) {
    background: white;
    border: solid 1px $border-color;
    border-radius: 12px;
    box-shadow: $shadow;
    overflow: hidden;
  }

  .login-form-logo {
    font-size: 100px;
    text-align: center;
    display: block;
    color: #19AEF0;
  }
}

.login-form {
  padding: 2em;

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
  }
}

.facebook-button {
  width: 100%;
  border: none;
  color: white;
  background: #29487D;
  margin-top: 15px;

  &:disabled {
    border: solid 1px $border-color;
  }

  &:hover,
  &:active,
  &:focus {
    color: white !important;
    background: rgba(41, 72, 125, 0.8) !important;
  }

  &.connected {
    background: transparent;
    color: #29487D;
    border: solid 1px #29487D;
  }
}

// Apple button
/* Sign in with Apple button styling */
.apple-signin-btn {
  display: flex;
  align-items: center;
  background-color: #000;
  color: #fff!important;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  //font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apple-signin-btn:hover {
  background-color: #333!important;
}