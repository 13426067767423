.app-logo {
  width: 100%;
  padding: 12px 20px;
  text-align: center;

  svg {
  color: #19AEF0;
    width: 80px;
    height: 30px;
  }
}

.ant-layout {
  overflow: hidden;
}

header.ant-layout-header {
  padding: 0 12px !important;
  @include mq(sm) {
    border-bottom: solid 1px $border-color;
    padding: 0 40px !important;
  }

  @include mq(ms) {
    overflow: hidden;
    white-space: nowrap;
  }

  .header-title {
    line-height: 10px!important;
  }
}

main.ant-layout-content, footer.ant-layout-footer {
  padding: 0 20px !important;

  @include mq(sm) {
    padding: 0 40px !important;
  }
}

footer.ant-layout-footer {
  @include mq(ms) {
    display: none;
  }

  padding: 20px !important;

  a {
    font-size: 12px !important;
  }

}

main.ant-layout-content {
  padding-bottom: 60px !important;
  min-height: 80vh;
  @include mq(sm) {
    padding-top: 20px !important;
  }
}

header, main {
  padding: 0 40px !important;
}

.desktop-sider {
  &:not(.ant-layout-sider-collapsed) {
    min-width: 260px !important;

    .ant-layout-sider-children {
      width: 260px;
    }
  }


  &.ant-layout-sider-collapsed {
    .hide-close {
      display: none;
    }
    .ant-layout-sider-children {
      width: 60px !important;
    }
  }

  .ant-layout-sider-children {
    overflow-y: scroll;
    position: fixed;
    border-right: solid 1px $border-color;
    padding-bottom: 50px!important;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      //background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      //background: rgba(136, 136, 136, 0.5);
      border-radius: 6px;
    }


    .ant-menu {
      border: none !important;
    }
  }


  .ant-menu-title-content {
    font-weight: 500;
    margin-left: 1em !important;
  }

  .ant-menu-item {
    margin: 8px auto;
  }

  .ant-menu-submenu, .ant-menu-item {
    svg {
      font-size: 18px;
    }
  }

  &.ant-layout-sider-collapsed {
    max-width: 60px!important;
    min-width: 60px!important;
    width: 60px!important;

    .app-logo {
      height: 60px;
      line-height: 60px;
      padding: 0;

      svg {
        width: 20px;
        height: 12px;
      }
    }

    .active-offer-link {
      display: none;
    }
  }
}

.search-recipes {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 100%;
  @include mq(sm) {
    margin-top: 0;
    width: 42%;
  }

  @include mq(lg) {
    width: 400px;
  }
}

// Hide mobile highlight background on click
input,
textarea,
button,
select,
a, li, div, span {
  -webkit-tap-highlight-color: transparent !important;
}

// Override ant tag default bg
.ant-tag {
  border-radius: 24px;

  &.ant-tag-default {
    border: solid 1px $border-color;
    background: transparent;
  }
}

// Override strong tag
strong {
  font-weight: bold !important;
}

// Scrollbar
.scroll-wrapper {
  flex-flow: inherit !important;
  display: flex;
  overflow-x: scroll;

  &:not(.scroll-modal) {
    @include mq(sm) {
      flex-flow: row wrap !important;
      display: flex;
      overflow-x: hidden;
    }
  }

  &::-webkit-scrollbar {
    @include mq(sm) {
      height: 6px;
    }
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.2);
    border-radius: 6px;
  }
}

// ant modal
.ant-modal-header {
  text-align: center !important;
}

.ant-modal-mask {
  backdrop-filter: blur(5px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(5px); /* Safari compatibility */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add some transparency */
}

.ant-modal-content {
  padding-bottom: 2em !important;
}

.text-center {
  text-align: center;
}

// recharts
.recharts-wrapper {
  cursor: pointer !important;

  text {
    fill: #828282;
    //#f3f3f3
  }

  .recharts-cartesian-axis-tick-line, .recharts-cartesian-grid-vertical, .recharts-cartesian-axis-line {
    display: none !important;
  }

  .recharts-cartesian-grid-horizontal {
    line {
      stroke: $border-color;
    }
  }

  .xAxis {
    .recharts-cartesian-axis-line {
      //display: block !important;
      //stroke: #c3c1c1 !important;
    }
  }

  .recharts-surface {
    border-radius: 4px;
  }

  .recharts-tooltip-wrapper {
    outline: none!important;
  }

  .recharts-default-tooltip {
    background: rgba(255, 255, 255, 0.5);
    outline: none!important;
    border: solid 1px $border-color!important;
    border-radius: 6px;
    opacity: 0.95;
    font-weight: 600 !important;
  }
}

.text-right {
  text-align: right;
}

.active-offer-link {
  @include mq(ms) {
    display: inline-flex;
    width: 30px;
    justify-content: center;
    height: 30px !important;
    text-align: center;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    .ant-menu-title-content {
      display: none;
    }

    img {
      width: 24px;
      font-size: 24px;
    }
  }

  &.custom {
    margin: 10px 0;
    background: linear-gradient(to left, #4650ff, #ac00a2);
    height: 50px!important;
  }

  svg {
    @include mq(sm) {
      margin-left: 10px;
    }
  }

  color: white !important;
  height: 60px !important;
  margin-left: 6px !important;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-left: 12px;
  cursor: pointer;

  .ant-typography {
    font-weight: bold !important;
    color: white !important;
  }

  .ant-menu-title-content {
    margin-left: 10px!important;
    line-height: 20px !important;
  }

  .timer {
    .ant-statistic-content-value, .ant-statistic-content {
      color: white !important;
      font-size: 12px !important;
    }
  }

  img {
    animation: pulse 2s infinite;
  }

  .anticon {
    transform: scale(1);
    font-size: 20px;
    animation: pulse 2s infinite;
    color: white;
  }
}

@keyframes pulse {
  0% {
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0))
  }

  50% {
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.8))
  }

  0.500% {
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0))
  }
}

.wp-content-text {
  color: $color!important;
}

// Motivation modal

.motivation-popup {
  .animated-bg-circle {
    width: 100px;
    height: 100px;
    background: white;
    margin: auto;
    display: block;
    margin-bottom: 70px!important;
  }

  h2, p {
    width: 100%;
    text-align: center;
    display: block;
    color: white !important;
  }

  img {
    margin: auto;
  }

  .ant-modal-content {
    background: linear-gradient(45deg, #ffb70c, #f17f52);
  }

  &.weight {
    .ant-modal-content {
      background: linear-gradient(-45deg, #0096c7, #7157ff);
    }
  }
}

// New user info

.fitlap-info-list {
  .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-item-meta-title {
    font-weight: normal!important;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-list-item-meta-title {
    margin: 0;
  }
}

.new-user-info-icon {
  cursor: pointer;

  .ant-progress {
    &.active-pulse {
      border-radius: 24px;
      box-shadow: 0 0 0 0 limegreen;
      transform: scale(1);
      animation: pulsering 2s infinite;
    }
  }

  @keyframes pulsering {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(50, 205, 50, 0.4);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(50, 205, 50, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(50, 205, 50, 0);
    }
  }

  .ant-progress-inner {
    width: 30px !important;
    height: 30px !important;
  }
}

.header-affix {
  z-index: 10;
  //z-index: 5!important;
}

.header-affix > .ant-affix {
  z-index: 500;
  box-shadow: $shadow;
  .ant-layout-header {
    border: none!important;
  }
}

.radio-buttons {
  white-space: inherit !important;

  .ant-radio-button-wrapper {
    border: 1px solid $border-color;
    border-radius: 24px !important;
    margin: 6px;
    outline: 0 !important;

    &:before {
      display: none !important;
    }
  }
}


.page-disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.2;
}

.darkmode {
  .access-required {
    background: #262626!important;
  }
}

.access-required {
  position: fixed;
  top: 50%;
  left: 50%;
  background: white;
  transform: translate(-50%, -50%);
  z-index: 449;
  padding: 40px;
  @include mq(ms) {
  padding: 20px;
    width: 80%;
  }
  border-radius: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.ant-notification-notice-btn {
  float: none!important;
  text-align: center;
}

// Loading app
.loading-icon {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  margin-top: -5px;
  width: 30px;
  height: 30px;
  position: relative;
  animation: loading-spinner 2.5s infinite linear both;
}

.loading-spinner-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: loading-spinner-dot 2.0s infinite ease-in-out both;
}

.loading-spinner-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #19aef0;
  border-radius: 100%;
  animation: loading-spinner-dot-before 2.0s infinite ease-in-out both;
}

.loading-spinner-dot:nth-child(1) { animation-delay: -1.1s; }
.loading-spinner-dot:nth-child(2) { animation-delay: -1.0s; }
.loading-spinner-dot:nth-child(3) { animation-delay: -0.9s; }
.loading-spinner-dot:nth-child(4) { animation-delay: -0.8s; }
.loading-spinner-dot:nth-child(5) { animation-delay: -0.7s; }
.loading-spinner-dot:nth-child(6) { animation-delay: -0.6s; }
.loading-spinner-dot:nth-child(1):before { animation-delay: -1.1s; }
.loading-spinner-dot:nth-child(2):before { animation-delay: -1.0s; }
.loading-spinner-dot:nth-child(3):before { animation-delay: -0.9s; }
.loading-spinner-dot:nth-child(4):before { animation-delay: -0.8s; }
.loading-spinner-dot:nth-child(5):before { animation-delay: -0.7s; }
.loading-spinner-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes loading-spinner {
  100% { transform: rotate(360deg); }
}

@keyframes loading-spinner-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes loading-spinner-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
      transform: scale(1.0);
    }
}

// Tooltip hide under modals and popups
.ant-tooltip {
  z-index: 5!important;
}

.tooltip-in-modal {
  z-index: 99999999 !important;
  &.hide-mobile {
    @include mq(ms) {
      display: none;
    }
  }
}

h4.ant-typography {
  @include mq(ms) {
    font-size: 18px!important;
  }
}

h1.ant-typography {
  @include mq(sm) {
    font-size: 30px !important;
    font-weight: 800;
  }
}

// Banner
.campaign-banner {
  cursor: pointer;
  text-align: center;
  border-radius: 12px;
  img {
    max-width: 100%;
  }
  @include mq(sm) {
    display: none;
  }
}

.scrollmargin {
  scroll-margin-top: 100px;
}

.new-user-discount {
  box-shadow: $boxshadow;
  padding: 20px 0;
  margin-bottom: 20px;
  margin-top: 10px;
  @include mq(sm) {
    margin: 0 0 40px 0;
    overflow: hidden;
  }

  .ant-statistic-content {
    font-size: 14px;
    color: $grey;
  }

  button {
    margin: 10px auto 0 auto;
    display: block;
  }
}

// Custom scrollbar for site
@include mq(sm) {

  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  *::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #cccccc;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

// Sheet
.sheet-modal {
  z-index: 1001!important;
  &.zindex-500 {
    z-index: 501!important;
  }
}

.react-modal-sheet-content, .react-modal-sheet-scroller  {
  padding-left: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
}

// Button group
.button-group {
  button:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .btn-two {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none!important;
  }
}

// Info alert global
.ant-alert-info {
  font-size: 14px!important;
  border-color: $border-color!important;
  //background: transparent!important;
}

// Wordpress blocks
.wp-block-table {
  margin: 0;
  padding: 0;

  table {
    width: 100%;
    border: solid 1px $border-color;
    border-right: none;
    border-bottom: none;
    border-radius: 12px;
    overflow: hidden;
  }

  td {
    border-bottom: solid 1px $border-color;
    border-right: solid 1px $border-color;
    padding: 6px;
  }

  .has-text-align-center {
    min-width: 60px;
    text-align: center;
    em {
      color: #52c41a;
    }
  }
}

.shadow-box {
  box-shadow: $boxshadow;
  padding: 12px 20px;
  border-radius: 12px;
}

.ant-form-item-label {
    padding: 0!important;
  label {
    color: $grey !important;
    font-weight: 500 !important;
    font-size: 13px!important;
    @include mq(sm) {
      font-size: 14px;
    }
  }
}

// ANTD message
.ant-message {
  z-index: 5053425300;
  font-family: $primary-font!important;
}

// Thank you page
.thank-you-page-logo {
  max-width: 80px;
  margin: auto;
  padding: 0;
  height: 60px;
  color: #19AEF0;
  @include mq(sm) {
    color: white;
  }
}

.thank-you {
  &.public-thank-you {

    margin-top: 40px;
    .ant-result {
      box-shadow: $shadow;
    }
  }
}

.challenge-card-wrapper {
  .ant-card-head-title {
  }
  box-shadow: $boxshadow
}