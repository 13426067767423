.fitlap-wrapped {
  background: linear-gradient(90deg, #2b56a4, #45038d);

  .ant-card {
    background: white;

    .ant-card-body {
      padding: 10px;
    }
  }

  .ant-list-item-meta-content {
    text-align: left;
  }
}

.fullpage-nav {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 40%;
  z-index: 25;
  width: 30px;

  .nav-dot {
    transition: $transition;
    display: block;
    margin: 5px 0;
    border-radius: 50%;
    background: white;
    width: 10px;
    height: 10px;
    opacity: 0.5;

    &.active {
      opacity: 1;
      width: 14px;
      height: 14px;
    }
  }
}

.fitlap-wrapped-slide {
  text-align: center;
  margin: auto;
  position: relative;
  padding: 20px;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include mq(sm) {
    padding-top: 20px !important;
    display: flex;
  }

  button {
    margin: auto;
    display: block;
  }

  h1 {
    color: white !important;
    font-weight: bold;
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.15);
    @include mq(sm) {
      font-size: 60px !important;
    }
  }

  h4 {
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.15);
    color: white !important;
    @include mq(sm) {
      font-size: 20px !important;
    }
  }

  button {
    width: auto !important;
    height: auto !important;
    min-width: 120px;
    font-size: 14px;
    @include mq(sm) {
      font-size: 18px;
    }
  }

  .wrapped-svg {
    bottom: 150px;
    width: 100%;
    position: absolute;
    right: 0;
    z-index: -1;

    svg {
      width: 100%;
    }

    &.min {
      svg {
        max-width: 260px !important;
        @include mq(sm) {
          max-width: 400px !important;
        }
      }
    }

    @include mq(sm) {
      position: absolute;
      right: 40px;
      bottom: 60px;
      width: auto;
      svg {
        width: 600px;
      }
    }
  }
}