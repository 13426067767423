// Shopping list print styles
.meal-plan-print-view {
  display: none;
}

@media print {
  @page {
    margin: 1cm 2cm;
  }

  .meal-plan-print-view {
    display: block;

    .print-meal {
      margin-top: 20px;
      page-break-inside: avoid;
    }

    .print-meal-ingredients {
      padding: 0 10px;
      border: solid 1px #eee;

      .recipe-ingredient {
        padding: 4px 0!important;
      }
    }

    ul, ol {
      padding-left: 20px;
    }

    li {
      padding: 0;
    }

    .print-meal-title {
      font-weight: bold ;
      font-size: 20px;
      color: $black;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .print-meal-ingredient:not(:last-child) {
      border-bottom: dashed 2px #eee;
    }
  }
}
