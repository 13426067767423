.daily-water-input {
  width: 120px;
  margin: 0 10px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.add-to-menu-modal {
  .date-arrow {
    cursor: pointer;
  }
}