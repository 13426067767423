.training-video {
  width: 100%;
  border-radius: 12px;
  border: none;
  height: 320px;
  @include mq(sm) {
    height: 440px;
  }
}

.hover-list {
  .ant-list-item {
    cursor: pointer;

    &:hover {
      .ant-typography, svg {
        color: $brand-color !important;
      }
    }
  }
}

.list-sub-items {
  .ant-tag {
    background: transparent;
    font-size: 10px;
    @include mq(sm) {
      font-size: 12px;
    }
  }
}

.weight-input-wrapper {
  .ant-input-number-wrapper {
    margin-top: -5px !important;
  }
}

.training-chart-container {
  font-size: 10px;
  font-family: $primary-font;
  @include mq(sm) {
    font-size: 12px;
  }
}

.training-daily-progress {
  margin-bottom: 20px;

  .anticon-check {
    color: #ee7752;
  }
}

.custom-chart-tooltip {
  background: white;
  box-shadow: $boxshadow;
  border-radius: 6px;
  padding: 10px;

  .muted-text {
    font-size: 12px;
  }
}

.exercise-form {
  .ant-input-number-group-addon {
    background: transparent !important;
    color: $grey !important;
  }
}

.training-top-tier {
  margin-bottom: 20px;
  background: linear-gradient(45deg, rgba(0, 49, 255, 0.1), transparent);
}

.training-leaderboard {
  margin: 40px 0;
  padding-top: 40px;

  .muted-text {
    font-size: 12px;
    @include mq(sm) {
      font-size: 14px;
    }
  }
}

.leaderboard-position {
  h3, h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
  }
}

.stopper-button-wrapper {
  position: absolute;
  left: 160px;
  bottom: 22px;
}


.video-modal {
  border-radius: 12px !important;
  overflow: hidden;
  @include mq(sm) {
    width: 768px !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-content {
    @include mq(sm) {
      width: 768px;
    }
    //background: black;
    height: auto;
    padding: 0 !important;
  }
}

.muscle-card {
  &.video-card {
    .ant-card-body {
      padding: 12px !important;
    }
  }

  &:hover {
    .muscle-icon {
      opacity: 1;
      filter: saturate(100%);
    }
  }
}

.muscle-icon {
  filter: saturate(0%);
  opacity: 0.6;
}

.play-video-row {
  cursor: pointer;

  &:hover {
    color: $brand-color;

    .play-video-icon {
      color: $brand-color;
    }
  }
}

.play-video-icon {
  color: $grey;
  font-size: 20px;
  padding-left: 10px;
}

.training-segment {
  margin-bottom: 8px;
  margin-top: 16px;
  background: #eee;
}

.darkmode {
  .training-segment {
    background: $border-color;
  }
}

.prev-arrow, .next-arrow {
  color: white;
  font-size: 24px;
  position: absolute;
  top: 120px;
  padding: 20px;
  opacity: 0.7;
  @include mq(sm) {
    font-size: 40px;
    top: 180px;
  }
  &.hidden-arrow {
    display: none;
  }
}

.prev-arrow {
  left: 0;
}

.next-arrow {
  right: 0;
}
