.blog-comments {
  margin-top: 20px;
  margin-bottom: 40px;

  .select-sort-comments {
    font-size: 14px !important;
  }

  .comments-empty {
    margin: 40px 0;
    color: $grey;
  }

  .ant-comment-content-author-time, .ant-comment-actions, .reply-action {
    color: $grey !important;
  }

  .ant-comment-content-author-time {
    position: absolute !important;
    right: 0 !important;
  }

  .active {
    background: #fafafa;
  }

  .ant-comment-content-author-name {
    font-weight: bold;
  }

  .ant-comment {
    padding: 12px;
    border: solid 1px $border-color !important;
    margin-bottom: 10px;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-comment-nested {
    margin-left: 20px;
    border-left: solid 1px $border-color;
    @include mq(sm) {
      margin-top: 12px !important;
    }

    .ant-comment {
      padding-bottom: 0;
      padding-right: 0;
      border: none !important;
    }
  }

  .ant-comment-inner {
    padding: 0;
  }

  .ant-comment-content-detail {
    p {
      margin: 0;
    }
  }

  .blog-comments-header {
    margin-bottom: 10px;

    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      @include mq(sm) {
        font-size: 24px;
      }
    }

    .ant-select {
      float: right;
    }
  }

  .ant-comment-avatar img {
    width: 100%;
    height: 100%;
  }

  a {
    color: $brand-color;

    &:hover {
      //color: lighten($brand-color, 5)
    }
  }

  .comment-list {
    margin-bottom: 10px;
    @include mq(sm) {
      border: none;
      margin: 0;
      padding: 0
    }

    .ant-comment:not(:last-child) {
      border-bottom: solid 1px $border-color;
    }
  }

  .create-comment {
    margin-bottom: 20px;

    textarea {
      margin-top: 10px;
      font-size: 14px;
    }

    button {
      font-size: 14px;
      margin-top: 12px;
    }
  }

  .ant-comment-actions {
    button {
      margin-right: 6px;
    }
  }

  .show-replies {
    font-size: 14px !important;
    margin-left: 20px;
    display: flex;
    align-items: center;

    svg {
      font-size: 12px;
      margin-left: 8px;
    }
  }
}

.post-heart {
  svg {
    fill: red !important;
    color: red;
  }
}

.emoji-counter {
  margin-right: 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.reaction-emote {
  @include mq(sm) {
  width: 30px;
  font-size: 30px;
  height: 30px;
  }
  width: 24px;
  height: 24px;
  font-size: 24px;
  background: white;
  border: solid 1px $border-color;
}