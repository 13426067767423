.app-settings-wrapper {
    .ant-checkbox-group {
      display: block;

      .ant-checkbox-wrapper {
        display: flex;
        margin: 0;
        padding: 0;
      }
    }
}

.avatar-uploader {
  .ant-upload {
    border-radius: 50%!important;
    overflow: hidden!important;
  }
}

// Edit family member
.default-eater {
  flex-direction: column;
  .ant-checkbox-wrapper {
    margin: 0;
    padding: 0;
  }
}

// meal settings
.user-macros {
  .ant-form-item-label {
    padding: 0!important;
    margin: 0!important;
    font-size: 14px!important;
  }
}